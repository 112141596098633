<template>
  <section class="cont">
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>数据中台</el-breadcrumb-item>
           <el-breadcrumb-item>SCRM</el-breadcrumb-item>
           <el-breadcrumb-item>成长值列表</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <!-- 主体内容 -->
     <el-row class="content-box">
        <el-row class="search-box">
           <el-row class="search-row">
              <el-row class="search-item">
                 <label>姓名：</label>
                 <el-input class="width-220"  v-model="userName" placeholder="姓名" clearable></el-input>
              </el-row>
              <el-row class="search-item">
                 <label>电话：</label>
                 <el-input class="width-220"  v-model="mobile" placeholder="电话" clearable></el-input>
              </el-row>
              <el-button class="bg-gradient" type="primary" icon="el-icon-search" @click="handleQuery(true)" v-text="$t('msg.search')">搜索</el-button>
              <el-button  icon="el-icon-refresh-right" plain @click="handleQuery(false)" v-text="$t('msg.reset')">重置</el-button>
           </el-row>
        </el-row>
        <el-row class="table-box">
           <!--表格内容-->
           <el-table ref="mainTable" v-loading="tableLoad" border fit :data="tableData"  :stripe="true" >
              <el-table-column prop="userName" label="姓名" min-width="50"></el-table-column>
              <el-table-column prop="createTime" label="创建时间" min-width="70"></el-table-column>
              <el-table-column prop="itemName" label="项目分类" min-width="70"></el-table-column>
              <el-table-column prop="itemValue" label="成长值" min-width="70"></el-table-column>
              <el-table-column prop="actualAmount" label="消费金额" min-width="70"></el-table-column>
           </el-table>
           <!-- 分页 -->
           <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging" />
         </el-row>
     </el-row>
  </section>
</template>

<script>
   import { urlObj } from '@/api/interface'
   import { mapState } from 'vuex'
   export default {
      data() {
         return {
            // 数据列表
            tableData: [], // 表格用户列表
            levelList: [],
            hotelId: '',
            tableLoad: true, // 表格加载效果
            total: 0, // 用户列表总条目数
            refresh: true, // 刷新组件
            // 会员查询列表
            page: 1, // 当前页 默认第一页
            limit: 0, // 每页显示数
            mobile: '', // 手机号
            userName: '', // 会员名称
         }
      },
      computed: {
         ...mapState(['hotelInfo'])
      },
      mounted() {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getMember()
      },
      methods: {
         // 获取列表
         getMember() {
            const param = {
               userName: this.userName,
               mobile: this.mobile,
               hotelId: this.hotelInfo.id,
            }
            const url = urlObj.growthList + `?limit=${this.limit}&page=${this.page}`
            this.$axios.post(url, param, 'json').then(res => {
               if (res.success) {
                  this.tableLoad = false
                  this.total = res.total
                  this.tableData = res.records
               }
            })
         },
         // 搜索角色
         handleQuery(bool) {
            this.page = 1
            if (!bool) {
               this.mobile = ''
               this.userName = ''
            }
         },
         // 改变每页数
         pageChange(num) {
            this.limit = num
            this.getMember()
         },
         // 改变当前页
         handlePaging(num) {
            this.page = num
            this.getMember()
         },
         // 查看详情
         handleDetail(row) {
            sessionStorage.setItem('memberInfo', JSON.stringify(row))
            this.$router.push('/member_detail')
         },
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id  && oldVal) {
                  this.getMember()
               }
            },
            deep: true
         }
      },
   }
</script>

<style lang="scss">
.head-pic {
  display: flex;
}
.head-pic .old-pic {
  border-radius: 6px;
  margin-right: 8px;
}
.head-pic .el-upload {
  width: 70px !important;
  height: 70px !important;
  line-height: 80px;
}
.head-pic .el-upload-list li {
  width: 70px !important;
  height: 70px !important;
}

/deep/ .el-tag--dark.is-hit {
  border-color: transparent;
}
.tag-pop .active .el-icon-check {
  display: inline;
}
.el-icon-check {
  display: none;
}
</style>
